import React from 'react';
import './styles/CustomCursor.css';

class CustomCursor extends React.Component {

    constructor(props) {
        super(props);
        this.state = {click: false};
    }

    render(){return <div>
        <div className={this.state.click ? 'CursorOne active' : 'CursorOne'}></div>
        <div className={this.state.click ? 'CursorTwo active' : 'CursorTwo'}></div>
        <div className={this.state.click ? 'CursorThree active' : 'CursorThree'}></div>
    </div>
    }

    componentDidMount(){

        const cursor = document.querySelector(".CursorOne")
        const cursorTwo = document.querySelector(".CursorTwo")
        const cursorThree = document.querySelector(".CursorThree")

        document.addEventListener("mousemove", e => {
            
            this.isClickable(e.pageX, e.pageY)
            cursor.setAttribute("style", "top:" + (e.pageY - 10)+"px; left:" + (e.pageX - 10) + "px;")
            cursorTwo.setAttribute("style", "top:" + (e.pageY - 7.5)+"px; left:" + (e.pageX - 7.5) + "px;")
            cursorThree.setAttribute("style", "top:" + (e.pageY - 5)+"px; left:" + (e.pageX - 5) + "px;")

        })

        
        

    }

    componentWillUnmount(){
        
        const cursor = document.querySelector(".CursorOne")
        const cursorTwo = document.querySelector(".CursorTwo")
        const cursorThree = document.querySelector(".CursorThree")

        document.removeEventListener("mousemove", e => {
            
            this.isClickable(e.pageX, e.pageY)
            cursor.setAttribute("style", "top:" + (e.pageY - 10)+"px; left:" + (e.pageX - 10) + "px;")
            cursorTwo.setAttribute("style", "top:" + (e.pageY - 7.5)+"px; left:" + (e.pageX - 7.5) + "px;")
            cursorThree.setAttribute("style", "top:" + (e.pageY - 5)+"px; left:" + (e.pageX - 5) + "px;")
        })

       
        
    }

    isClickable(x, y){

        let elems = document.querySelectorAll(':hover');
        let found = false;

        if(elems === null ){
            return
        }

        elems.forEach(element => {
            if(element.classList.contains("clickable")){
                found = true
                this.setState({click: true})
            }
        });

        if(found === false){
            this.setState({click: false})
        }

    }

}

export default CustomCursor