import React from 'react';
import './styles/WordCloudWord.css';
import { motion } from "framer-motion"

const variants = {
    dataWeight2: {"--size": 2, "--opacity": "20%"},
    dataWeight3: {"--size": 3},
    dataWeight4: {"--size": 4},
    dataWeight5: {"--size": 6},
    dataWeight6: {"--size": 8},
    dataWeight7: {"--size": 10},
    dataWeight8: {"--size": 13},
    dataWeight9: {"--size": 16},
    opacityWeight1: {"--opacity": "20%"},
    opacityWeight2: {"--opacity": "30%"},
    opacityWeight3: {"--opacity": "40%"},
    opacityWeight4: {"--opacity": "50%"},
    opacityWeight5: {"--opacity": "60%"},
    opacityWeight6: {"--opacity": "70%"},
    opacityWeight7: {"--opacity": "80%"},
    opacityWeight8: {"--opacity": "90%"},
    opacityWeight9: {"--opacity": "100%"}
}

function chooseSizeVariant(num){

    switch(num){
        case 1:
            return "dataWeight1"
        case 2:
            return "dataWeight2"      
        case 3:
            return "dataWeight3"    
        case 4:
            return "dataWeight4"   
        case 5:
            return "dataWeight5"  
        case 6:
            return "dataWeight6"         
        case 7:
            return "dataWeight7"
        case 8:
            return "dataWeight8"
        case 9:
            return "dataWeight9"
        default:
            return "dataWeight1"

          
    }

}

function chooseOpacityVariant(num){

    switch(num){
        case 1:
            return "opacityWeight1"
        case 2:
            return "opacityWeight2"      
        case 3:
            return "opacityWeight3"    
        case 4:
            return "opacityWeight4"   
        case 5:
            return "opacityWeight5"  
        case 6:
            return "opacityWeight6"         
        case 7:
            return "opacityWeight7"
        case 8:
            return "opacityWeight8"
        case 9:
            return "opacityWeight9"
        default:
            return "opacityWeight1"
          
    }

}



class WordCloudWord extends React.Component {

    constructor(props) {
        super(props);
        this.state = {random1: Math.floor(Math.random() * 10 + 1), random2: Math.floor(Math.random() * 8 + 1), mouseOver: false};
    }

    render(){
                return <motion.li 
                    key={this.key}
                    id={"WordCloudWord-" + this.props.element}
                    className="WordCloudWord clickable"
                    variants={variants}
                    transition={{duration: 1}}
                    initial={[chooseSizeVariant(this.state.random1), chooseOpacityVariant(this.state.random2)]}
                    whileInView={[chooseSizeVariant(this.state.random1), chooseOpacityVariant(this.state.random2)]}
                    onMouseEnter={() => this.mouseOver()}
                    onMouseLeave={() => this.mouseLeave()}
                    onClick={this.props.onClick} 
                    
                >
                    {this.props.element}
                </motion.li>
    }

    componentDidMount(){
        this.timerID = setInterval(
            () => this.tick(),
        1000
          );
    }

    componentWillUnmount(){
        clearInterval(this.timerID);
    }

    tick() {
        if(!this.state.mouseOver){
            this.setState({random1: this.state.random1, random2: Math.floor(Math.random() * 8 + 1), mouseOver: false}); 
        }  
    }

    mouseOver(){
        this.setState({random1: this.state.random1, random2: 9, mouseOver: true})
    }

    mouseLeave(){
        
        this.setState({random1: this.state.random1, random2: this.state.random2, mouseOver: false})
    }

}

export default WordCloudWord