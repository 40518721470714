import React from 'react';
import './styles/OurClientsSection.css';
import { motion } from "framer-motion"

const clientLogoData = [
    "logos/clients/seven_network.png",
    "logos/clients/7_two_network.svg.png",
    "logos/clients/channel_9.png",
    "logos/clients/food_sa.png",
    "logos/clients/holdfast_bay.png",
    "logos/clients/outdoors_indoors.png",
    "logos/clients/pascale_media.png",
    "logos/clients/san_remo.png",
    "logos/clients/sbs.png",
    "logos/clients/travel_auctions.png"
]

const parentVariant = {
    initial: { opacity: 0 },
    animate: { opacity: 1, transition: { staggerChildren: 0.3, duration: 2}},
  };
  
  const childrenVariant = {
    initial: { opacity: 0},
    animate: { opacity: 1, transition: { duration: 2}}
  }


class OurClientsSection extends React.Component {

    render(){return <div className='OurClientsSectionContainer'>
        <motion.div 
            className='ClientLogoGrid'
            initial="initial"
            whileInView="animate"
            transition="transition"
            variants={parentVariant}
        >
            
        {
            clientLogoData.map((element, index) => {
                        return  <motion.img 
                            key={index + " - client"} 
                            alt="client-logo" 
                            src={element}
                            variants={childrenVariant}
                        />
            })
        }

        </motion.div>
    </div>
    }
}

export default OurClientsSection