import React from 'react';
import './styles/Navbar.css';

const pageLinksData = [
    ["WORK", "#work"],
    ["SERVICES", "#services"],
    ["CLIENTS", "#clients"],
    ["CONTACT", "#contact"]
]

const socialLinksData = [
    ["logos/socials/youtube.png", "https://www.youtube.com/channel/UC2IimnTir_ZkdhAoIh8jBWQ", "youtube"],
    ["logos/socials/facebook.png", " https://www.facebook.com/tavproductionssa/", "facebook"],
    ["logos/socials/instagram.png", "https://www.instagram.com/amandaseasyeats/?hl=en", "instagram"],
    ["logos/socials/linkedin.png", "https://au.linkedin.com/company/tav-productions-sa", "linkedin"]
]

function setActive(){

    document.getElementById("CollapsableMenu").className = "CollapsableMenu Active";
    document.getElementById("CollapsableMenuNavbarBackground").className = "CollapsableMenuNavbarBackground ActiveBackground";

}

function setInactive(){

    document.getElementById("CollapsableMenu").className = "CollapsableMenu";
    document.getElementById("CollapsableMenuNavbarBackground").className = "CollapsableMenuNavbarBackground";

}

class Navbar extends React.Component {

    render(){

        return (
          <div className="Navbar">
               
            <div className="NavbarBackground">
              <div className="NavbarBackgroundColour"></div>
              <svg
                className="NavbarBackgroundNoise"
                xmlns="http://www.w3.org/2000/svg"
                version="1.1"
                xlink="http://www.w3.org/1999/xlink"
                svgjs="http://svgjs.dev/svgjs"
              >
                <defs>
                  <filter
                    id="nnnoise-filter-2"
                    x="-20%"
                    y="-20%"
                    width="140%"
                    height="140%"
                    filterUnits="objectBoundingBox"
                    primitiveUnits="userSpaceOnUse"
                    colorInterpolationFilters="linearRGB"
                  >
                    <feTurbulence
                      type="fractalNoise"
                      baseFrequency="0.080"
                      numOctaves="4"
                      seed="15"
                      stitchTiles="stitch"
                      x="0%"
                      y="0%"
                      width="100%"
                      height="100%"
                      result="turbulence"
                    ></feTurbulence>
                    <feSpecularLighting
                      surfaceScale="15"
                      specularConstant="0.75"
                      specularExponent="20"
                      lightingColor="hsl(22.5, 100%, 53%)"
                      x="0%"
                      y="0%"
                      width="100%"
                      height="100%"
                      in="turbulence"
                      result="specularLighting"
                    >
                      <feDistantLight
                        azimuth="3"
                        elevation="100"
                      ></feDistantLight>
                    </feSpecularLighting>
                  </filter>
                </defs>
                <rect
                  width="10000"
                  height="10000"
                  fill="hsl(22.5, 40%, 98%)"
                ></rect>
                <rect
                  width="10000"
                  height="10000"
                  fill="hsl(23, 100%, 33%)"
                  filter="url(#nnnoise-filter)"
                ></rect>
                </svg>
            </div>
            
            <div className="PageLinksContainer">
              <a href="#top" className="MainLogoAnchor">
                <div className='MainLogoContainer'><img
                  className="MainLogo clickable"
                  src="logos/TAVLogo.png"
                  alt="mainLogo"
                ></img>
                <span className='MainLogoText'>
                  TAV PRODUCTIONS
                </span>
                </div>
              </a>

              {pageLinksData.map((element) => {
                return (
                  <a
                    className="Heading PageLinks clickable"
                    href={element[1]}
                    key={"pagelink-" + element[1]}
                  >
                    {element[0]}
                  </a>
                );
              })}
            </div>

            <div className="SocialLinksContainer">
              {socialLinksData.map((element) => {
                return (
                  <a
                    className='clickable'
                    target="_blank"
                    rel="noreferrer"
                    href={element[1]}
                    key={"sociallink-" + element[2]}
                  >
                    <img
                      className="SocialLinks"
                      src={element[0]}
                      alt={element[2]}
                    ></img>
                  </a>
                );
              })}
            </div>

            <svg
              onClick={() => {
                setActive();
              }}
              className="HamburgerIcon clickable"
              viewBox="0 0 100 80"
              width="40"
              height="40"
            >
              <rect rx="8" width="100" height="10"></rect>
              <rect rx="8" y="40" width="100" height="10"></rect>
              <rect rx="8" y="80" width="100" height="10"></rect>
            </svg>

            <div className="CollapsableMenu" id="CollapsableMenu">
              
              <div id="CollapsableMenuNavbarBackground" className="CollapsableMenuNavbarBackground">
               <div className="NavbarBackgroundColour"></div>
               <svg
                className="NavbarBackgroundNoise"
                xmlns="http://www.w3.org/2000/svg"
                version="1.1"
                xlink="http://www.w3.org/1999/xlink"
                svgjs="http://svgjs.dev/svgjs"
              >
                <defs>
                  <filter
                    id="nnnoise-filter-3"
                    x="-20%"
                    y="-20%"
                    width="140%"
                    height="140%"
                    filterUnits="objectBoundingBox"
                    primitiveUnits="userSpaceOnUse"
                    colorInterpolationFilters="linearRGB"
                  >
                    <feTurbulence
                      type="fractalNoise"
                      baseFrequency="0.080"
                      numOctaves="4"
                      seed="15"
                      stitchTiles="stitch"
                      x="0%"
                      y="0%"
                      width="100%"
                      height="100%"
                      result="turbulence"
                    ></feTurbulence>
                    <feSpecularLighting
                      surfaceScale="15"
                      specularConstant="0.75"
                      specularExponent="20"
                      lightingColor="hsl(22.5, 100%, 53%)"
                      x="0%"
                      y="0%"
                      width="100%"
                      height="100%"
                      in="turbulence"
                      result="specularLighting"
                    >
                      <feDistantLight
                        azimuth="3"
                        elevation="100"
                      ></feDistantLight>
                    </feSpecularLighting>
                  </filter>
                </defs>
                <rect
                  width="10000"
                  height="10000"
                  fill="hsl(22.5, 40%, 98%)"
                ></rect>
                <rect
                  width="10000"
                  height="10000"
                  fill="hsl(23, 100%, 33%)"
                  filter="url(#nnnoise-filter)"
                ></rect>
               </svg>
              </div>
              
              <div className="ExitMenuContainer">
                <svg
                  onClick={() => {
                    setInactive();
                  }}
                  className="ExitIcon"
                  viewBox="0 0 100 80"
                  width="40"
                  height="40"
                >
                  <line rx="8" x1="0" y1="100" x2="100" y2="0"></line>
                  <line rx="8" x1="0" y1="0" x2="100" y2="100"></line>
                </svg>
              </div>

              <div className="CollapsableMenuPageLinks">
                {pageLinksData.map((element) => {
                  return (
                    <a
                      onClick={() => {
                        setInactive();
                      }}
                      className="Heading CollapsableMenuPageLink"
                      href={element[1]}
                      key={"pagelink-" + element[1]}
                    >
                      {element[0]}
                    </a>
                  );
                })}
              </div>

              <div className="CollapsableMenuSocialLinks">
                {socialLinksData.map((element) => {
                  return (
                    <a
                      onClick={() => {
                        setInactive();
                      }}
                      target="_blank"
                      rel="noreferrer"
                      href={element[1]}
                      key={"sociallink-" + element[2]}
                    >
                      <img
                        className="CollapsableMenuSocialLink"
                        src={element[0]}
                        alt={element[2]}
                      ></img>
                    </a>
                  );
                })}
              </div>
            </div>
          </div>
        );
    }

}

export default Navbar