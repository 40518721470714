import React from 'react';
import './styles/ContentSection.css';


class ContentSection extends React.Component {

    render(){return <div 
            className='ContentSectionContainer'
            id={this.props.id + "-content"}>
        <div id={this.props.id} className='anchor'></div>
        <p className='Heading'>{this.props.heading}</p>
        {this.props.children}
    </div>
    }
}

export default ContentSection