import React from 'react';
import './styles/WordCloud.css';
import WordCloudWord from "./WordCloudWord"
import { motion } from "framer-motion"

const parentVariant = {
    initial: { opacity: 0 },
    animate: { opacity: 1, transition: { duration: 6}}
};
  



class WordCloud extends React.Component {

    render(){return <motion.div 
            initial="initial"
            whileInView="animate"
            transition="transition"
            variants={parentVariant}
            className="WordCloud">
        <ul>
        {
            this.props.data.map((element, index) => {
                return <WordCloudWord onClick={this.props.onClick} key={"wordCloudWord-" + index} element={element}></WordCloudWord>
            })
        }
        </ul>
    </motion.div>
    }

}

export default WordCloud