import React from "react";
import "./styles/BackgroundNoise.css";

class BackgroundNoise extends React.Component {
  render() {
    return (
      <div className="BackgroundNoiseContainer">
        <div className="BackgroundColour"></div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          version="1.1"
          xlink="http://www.w3.org/1999/xlink"
          svgjs="http://svgjs.dev/svgjs"
        >
          <defs>
            <filter
              id="nnnoise-filter"
              x="-20%"
              y="-20%"
              width="140%"
              height="140%"
              filterUnits="objectBoundingBox"
              primitiveUnits="userSpaceOnUse"
              colorInterpolationFilters="linearRGB"
            >
              <feTurbulence
                type="fractalNoise"
                baseFrequency="0.080"
                numOctaves="4"
                seed="15"
                stitchTiles="stitch"
                x="0%"
                y="0%"
                width="100%"
                height="100%"
                result="turbulence"
              ></feTurbulence>
              <feSpecularLighting
                surfaceScale="15"
                specularConstant="0.75"
                specularExponent="20"
                lightingColor="hsl(22.5, 100%, 53%)"
                x="0%"
                y="0%"
                width="100%"
                height="100%"
                in="turbulence"
                result="specularLighting"
              >
                <feDistantLight azimuth="3" elevation="100"></feDistantLight>
              </feSpecularLighting>
            </filter>
          </defs>
          <rect width="10000" height="10000" fill="hsl(22.5, 40%, 98%)"></rect>
          <rect
            width="10000"
            height="10000"
            fill="hsl(23, 100%, 33%)"
            filter="url(#nnnoise-filter)"
          ></rect>
        </svg>
      </div>
    );
  }
}

export default BackgroundNoise;
