import React from 'react';
import './styles/WeCreateSection.css';
import { motion, AnimatePresence } from "framer-motion"
import SampleVideo from './SampleVideo';

const url = 'http://139.99.236.13:4000/vimeo'

class WeCreateSection extends React.Component {

    constructor(props) {
        super(props);
        this.state = {featureVideosData: [], finishedLoading: false};
    }

    render(){
    
    return <>

    <motion.div 
        className='WeCreateSectionContainer'
        style={{gridTemplateColumns: "repeat(auto-fit, minmax(450px, 500px))"}}>
        
        {
        this.state.finishedLoading ? 

        this.state.featureVideosData.length > 0 ? 
        <AnimatePresence>
        { 
            this.state.featureVideosData.map((element, iter) => {
                return <SampleVideo videoName={element[0]} videoSrc={element[1]}></SampleVideo>  
            }) 
        }
        </AnimatePresence> : <div className='LoadingText'>
            Damn....Nothing <br/> You'll Have to Check Back Later
        </div>
        : 
        <motion.div
            className='LoadingText'
            transition={{loop: Infinity, duration: 4}}
            initial={{opacity: 0}}
            animate={{opacity: 1}}
        >
        Loading Content, <br/> Please Wait a Moment
        </motion.div>
        } 
    </motion.div>
    </>
    }

    getVideosFromVimeo(){

        this.setState({featureVideosData: this.state.featureVideosData, finishedLoading: false})

        fetch(url, {
            method: "GET",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
            }
        })
        .then(
          response => response.json()
        )
        .then(
            data => {
                this.setState({featureVideosData: data.data, finishedLoading: true})
            }
        )
        .catch(
          error =>{ 
            this.setState({featureVideosData: this.state.featureVideosData, finishedLoading: true})
            console.error(error)
          }
        )
    }

    componentDidMount(){

        this.getVideosFromVimeo()

    }

    componentWillUnmount(){}

}

export default WeCreateSection