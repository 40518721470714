import React from 'react';
import './styles/WhatWeDoSection.css';
import WordCloud from './WordCloud';
import { motion } from "framer-motion"

const servicesData = [
    "Consulting",
    "Planning",
    "Research",
    "Script writing",
    "Filming",
    "Audio recording",
    "Editing", 
    "Graphics",
    "Colour grading",
    "Audio post production",
    "Production management",
    "Location survey",
    "Transcriptions",
    "Lighting",
    "Autocue",
    "Make up",
    "Talent",
    "Animation",
    "Music",
    "Voice over recordings",
    "Social media ",
    "Producing",
    "Directing"
]

const tavDescription = <>
    TAV Productions is a boutique video production company based in Adelaide, Australia. The company is spearheaded by Executive Producer Amanda Duggan and her team of story-sellers. They take your product, service, or message, bring it to life, and tell its story. They are known for producing big ideas and creating engaging content that resonates with their clients’ target audience. <br/><br/>
    TAV Productions has worked with a variety of clients, including small businesses, non-profit organizations, and large corporations, producing content for a range of industries, including healthcare, education, finance, and technology. <br/><br/>
    To view a list of some of their services simply <span className='bolded'> click here. </span>
</>
class WhatWeDoSection extends React.Component {

    constructor(props) {
        super(props);
        this.state = {clicked: false};
    }

    render(){return <div className='WhatWeDoSectionContainer'>
        {this.state.clicked ? 
         <WordCloud onClick={() => {this.setState({clicked: false})}} data={servicesData}></WordCloud> : 
         <motion.div 
            className='DescriptionTextContainer'
            transition={{duration: 1}}
            initial={{opacity: 0}}
            whileInView={{opacity: 1}}
        >
            <p className='DescriptionText clickable' onClick={() => {this.setState({clicked: true})}}>
                {tavDescription}
            </p>
        </motion.div>}
    </div>
    }

}

export default WhatWeDoSection